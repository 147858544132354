import {
  Check,
  MessageSquare,
  MoreHorizontal,
  Pencil,
  Search,
  Shuffle,
  SquarePen,
  Trash2,
} from 'lucide-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import quizStore, { QuestionObj } from '../store/QuizStore';
import { showDialog, showToast } from '../utils/toast';
import PaginationComponent from './PaginationComponent';
import ReorderQuestions from './ReorderQuestions';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Input } from './ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './ui/table';
import WriteQuestionDialog, {
  openQuestionFormDialog,
} from './WriteQuestionDialog';

interface QuestionTableProps {
  questions: QuestionObj[];
  columnState?: {
    quizName?: boolean;
    category?: boolean;
    number?: boolean;
    question?: boolean;
    answer?: boolean;
  };
  isSingleQuiz?: boolean;
}

const QuestionTable: React.FC<QuestionTableProps> = observer(
  ({ questions, columnState, isSingleQuiz = false }) => {
    const [editingId, setEditingId] = useState<number | null>(null);
    const [editedQuestion, setEditedQuestion] = useState('');
    const [editedAnswer, setEditedAnswer] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [searchTerm, setSearchTerm] = useState('');
    const [visibleColumns, setVisibleColumns] = useState({
      quizName: false,
      category: false,
      number: isSingleQuiz,
      question: true,
      answer: true,
      ...columnState,
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [reorderQuestions, setReorderQuestions] = useState<boolean>(false);
    const [chatQuery, setChatQuery] = useState<string>('');
    const [chatGPTResponse, setChatGPTResponse] = useState<string>('');
    const [chatAreaQuestion, setChatAreaQuestion] =
      useState<QuestionObj | null>(null);

    useEffect(() => {
      setCurrentPage(1);
    }, [questions]);

    const handleEdit = (question: QuestionObj) => {
      setEditingId(question.id);
      setEditedQuestion(question.question);
      setEditedAnswer(question.answer);
    };

    const handleSave = (id: number) => {
      quizStore.updateQuestion(id, editedQuestion, editedAnswer);
      setEditingId(null);
    };

    const handleGenerateSimilar = (question: QuestionObj) => {
      const closeToast = showToast('Generating similar questions...');
      quizStore.generateSimilarQuestions(question.question).finally(closeToast);
    };

    const handleFactCheck = async (question: QuestionObj) => {
      const closeToast = showToast('Fact checking question...');
      const response = await quizStore.factCheck(question);
      closeToast();
      showDialog(response);
    };

    const handleAddToQuiz = (questionId: number) => {
      quizStore.addQuestionToQuiz(questionId);
    };

    const handleRemoveFromQuiz = (questionId: number) => {
      quizStore.removeQuestionFromQuiz(questionId);
    };

    const toggleColumnVisibility = (column: keyof typeof visibleColumns) => {
      setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
    };

    const filteredQuestions = useMemo(() => {
      return questions.filter((question) =>
        Object.values(question).some(
          (value) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }, [questions, searchTerm]);

    const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage);

    const paginatedQuestions = useMemo(() => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      return filteredQuestions.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredQuestions, currentPage, itemsPerPage]);

    const missingCategories = quizStore.getMissingCategoriesInCurrentQuiz();

    if (reorderQuestions) {
      if (!isSingleQuiz) {
        console.error('Reorder questions is only available for single quizzes');
        return null;
      }
      return (
        <>
          <Button onClick={() => setReorderQuestions(false)}>
            Close Reorder Questions
          </Button>
          <ReorderQuestions questions={questions} />
        </>
      );
    }

    return (
      <div className="pagePadding">
        {chatAreaQuestion && (
          <div className="chat-area">
            <p>Ask ChatGPT something about {chatAreaQuestion.question}</p>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const closeToast = showToast('Asking ChatGPT...');
                const response = await quizStore
                  .askQuestion(chatAreaQuestion, chatQuery)
                  .finally(() => closeToast());
                setChatGPTResponse(response);
              }}
            >
              <Input
                type="text"
                value={chatQuery}
                onChange={(e) => setChatQuery(e.target.value)}
              />
              <Button type="submit">Ask</Button>
              <Button onClick={() => setChatAreaQuestion(null)}>Close</Button>
            </form>
            {chatGPTResponse && (
              <>
                <p>ChatGPT</p>
                <span>{chatGPTResponse}</span>
              </>
            )}
          </div>
        )}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <Input
              placeholder="Search questions..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
              className="w-64"
            />
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">Toggle Columns</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {Object.entries(visibleColumns).map(([column, isVisible]) => (
                <DropdownMenuCheckboxItem
                  key={column}
                  checked={isVisible}
                  onCheckedChange={() =>
                    toggleColumnVisibility(
                      column as keyof typeof visibleColumns,
                    )
                  }
                >
                  {column.charAt(0).toUpperCase() + column.slice(1)}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            variant={'outline'}
            onClick={() => openQuestionFormDialog(setDialogOpen)}
          >
            Add Question
          </Button>
          <WriteQuestionDialog open={dialogOpen} setOpen={setDialogOpen} />
          <div className="flex items-center space-x-2">
            <span>Count</span>
            <Select
              value={itemsPerPage.toString()}
              onValueChange={(value: string) => {
                setItemsPerPage(Number(value));
                setCurrentPage(1);
              }}
            >
              <SelectTrigger className="w-[80px]">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="10">10</SelectItem>
                <SelectItem value="25">25</SelectItem>
                <SelectItem value="50">50</SelectItem>
                <SelectItem value="100">100</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              {visibleColumns.quizName && <TableHead>Quiz Name</TableHead>}
              {visibleColumns.category && <TableHead>Category</TableHead>}
              {visibleColumns.number && <TableHead>#</TableHead>}
              {visibleColumns.question && <TableHead>Question</TableHead>}
              {visibleColumns.answer && <TableHead>Answer</TableHead>}
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedQuestions.map((question) => (
              <TableRow key={question.id}>
                {visibleColumns.quizName && (
                  <TableCell>{question.quizName ?? ''}</TableCell>
                )}
                {visibleColumns.category && (
                  <TableCell>{question.category ?? ''}</TableCell>
                )}
                {visibleColumns.number && (
                  <TableCell className="max-w-[16px]">
                    {question.number ?? ''}
                  </TableCell>
                )}
                {visibleColumns.question && (
                  <TableCell className="max-w-[320px]">
                    {editingId === question.id ? (
                      <Input
                        value={editedQuestion}
                        onChange={(e) => setEditedQuestion(e.target.value)}
                      />
                    ) : (
                      question.question
                    )}
                  </TableCell>
                )}
                {visibleColumns.answer && (
                  <TableCell className="max-w-[160px]">
                    {editingId === question.id ? (
                      <Input
                        value={editedAnswer}
                        onChange={(e) => setEditedAnswer(e.target.value)}
                      />
                    ) : (
                      question.answer
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <div className="flex space-x-2">
                    {editingId === question.id ? (
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleSave(question.id)}
                        title={'Save'}
                      >
                        <Check className="h-4 w-4" />
                      </Button>
                    ) : (
                      <>
                        {!question.quizName ? (
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleAddToQuiz(question.id)}
                            title={'Add to Quiz'}
                          >
                            <SquarePen className="h-4 w-4" />
                          </Button>
                        ) : (
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleRemoveFromQuiz(question.id)}
                            title="Remove from Quiz"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        )}
                      </>
                    )}
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon">
                          <span className="sr-only">Open menu</span>
                          <MoreHorizontal className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        {editingId === question.id ? (
                          <DropdownMenuItem
                            onClick={() => handleSave(question.id)}
                          >
                            <Check className="mr-2 h-4 w-4" />
                            <span>Save</span>
                          </DropdownMenuItem>
                        ) : (
                          <DropdownMenuItem
                            onClick={() => handleEdit(question)}
                          >
                            <Pencil className="mr-2 h-4 w-4" />
                            <span>Edit</span>
                          </DropdownMenuItem>
                        )}
                        <DropdownMenuItem
                          onClick={() => {
                            if (question.approved) {
                              quizStore.unapproveQuestion(question.id);
                            } else {
                              quizStore.approveQuestion(question.id);
                            }
                          }}
                        >
                          <Check className="mr-2 h-4 w-4" />
                          <span>
                            {question.approved ? 'Unapprove' : 'Approve'}
                          </span>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => handleGenerateSimilar(question)}
                        >
                          <Shuffle className="mr-2 h-4 w-4" />
                          <span>Generate Similar</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => handleFactCheck(question)}
                        >
                          <Search className="mr-2 h-4 w-4" />
                          <span>Fact Check</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => setChatAreaQuestion(question)}
                        >
                          <MessageSquare className="mr-2 h-4 w-4" />
                          <span>Ask ChatGPT</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex items-center justify-between space-x-2 py-4">
          {totalPages > 1 && (
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
        {isSingleQuiz && (
          <div>
            <p>{questions.length} questions</p>
            <Button onClick={() => quizStore.categorizeQuestions()}>
              Categorize Questions
            </Button>
            <Button onClick={() => setReorderQuestions(true)}>
              Reorder Questions
            </Button>
            {missingCategories.length > 0 && (
              <div>
                <p>Missing categories</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                  {missingCategories.map((category) => (
                    <Button
                      key={category}
                      onClick={() => {
                        const closeToast = showToast('Generating questions...');
                        quizStore
                          .generateQuestionsForCategory(category)
                          .finally(() => {
                            closeToast();
                          });
                      }}
                    >
                      {category}
                    </Button>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        <Button
          onClick={async () => {
            const closeToast = showToast(
              'Generating quiz from filtered questions...',
            );
            quizStore
              .generateFromFilteredQuestions(filteredQuestions)
              .finally(() => {
                closeToast();
              });
          }}
        >
          Generate similar to filtered
        </Button>
      </div>
    );
  },
);

export default QuestionTable;
