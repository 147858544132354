import { saveAs } from 'file-saver';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  CATEGORIZE_QUESTIONS,
  EXPLAIN_FORMAT,
  FACT_CHECK,
  GENERATE_QUESTIONS_FROM_PAST_QUIZZES,
  GENERATE_SIMILAR_QUESTIONS,
} from '../prompts/prompts';
import { quickToast } from '../utils/toast';
import { callAi, Message } from './aiCall';

// TODO: clean up this interface because quizStore is a megaclass

type QuestionObjParams = {
  id: number;
  question: string;
  answer: string;
  category?: string;
  approved?: boolean;
  number?: number;
  quizName?: string;
};

export class QuestionObj {
  id: number;
  question: string;
  answer: string;
  category?: string;
  approved: boolean;
  // what number question was it (e.g 1 is the first question)
  number?: number;
  quizName?: string;

  constructor({
    id,
    question,
    answer,
    category,
    approved = false,
    number,
    quizName,
  }: QuestionObjParams) {
    this.id = id;
    this.question = question;
    this.answer = answer;
    this.category = category;
    this.approved = approved;
    this.number = number;
    this.quizName = quizName;
  }
}

export interface QuizObj {
  // quiz questions are stored in the questions array
  name: string;
  description: string;
  // TODO: think of a better name for this
  // we use these to generate new quizzes from
  favourite: boolean;
  lastUpdated: Date;
}

async function generateQuestionsHelper(messages: Message[]) {
  const response = await callAi(messages);
  const json = response.substring(
    response.indexOf('['),
    response.lastIndexOf(']') + 1,
  );
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error('Error parsing JSON', e);
    console.error('Response:', response);
    throw e;
  }
}

function generateFromPastQuizzesPrompt(
  quizzes: string[],
  questions: QuestionObj[],
) {
  // Print all quizzes sequentially with each quiz including all questions in numbered order
  let allQuestionsString = '';
  quizzes.forEach((quizName) => {
    const quizQuestions = questions.filter((q) => q.quizName === quizName);
    // sort in question order
    quizQuestions.sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
    allQuestionsString += `## ${quizName}\n`;
    quizQuestions.forEach((q) => {
      allQuestionsString += `${q.number}. ${q.question} (answer: ${q.answer})\n`;
    });
  });
  return GENERATE_QUESTIONS_FROM_PAST_QUIZZES + allQuestionsString;
}

class QuizStore {
  questions: QuestionObj[] = [];
  quizzes: QuizObj[] = [];
  currentQuiz: string;
  categories: string[] = [];
  nextId = 1;

  constructor() {
    makeAutoObservable(this);
    this.currentQuiz = this.quizzes[0]?.name;
    if (!this.currentQuiz) {
      this.quizzes.push({
        name: 'Current',
        description: '',
        favourite: false,
        lastUpdated: new Date(),
      });
      this.currentQuiz = 'Current';
    }
    this.loadState();
  }

  get scratchpadQuestions() {
    return this.questions.slice().reverse();
  }

  get approvedQuestions() {
    return this.questions
      .filter((q) => q.approved && !q.quizName)
      .slice()
      .reverse();
  }

  get currentQuizQuestions() {
    return this.questions
      .filter((q) => q.quizName === this.currentQuiz)
      .sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
  }

  get pastQuizQuestions() {
    return this.questions
      .filter((q) => q.quizName && q.quizName !== this.currentQuiz)
      .slice()
      .reverse();
  }

  get favouriteQuizzes() {
    return this.quizzes.filter((q) => q.favourite).map((q) => q.name);
  }

  createNewQuiz(quizName: string) {
    // name must be unique
    if (this.quizzes.some((q) => q.name === quizName)) {
      console.log('Quiz name must be unique');
      quickToast('Quiz name must be unique');
      return;
    }
    const newQuiz = {
      name: quizName,
      description: '',
      favourite: false,
      lastUpdated: new Date(),
    };
    this.quizzes = [newQuiz, ...this.quizzes];
    this.setCurrentQuiz(quizName);
    return newQuiz;
  }

  renameQuiz(oldName: string, newName: string) {
    if (this.quizzes.some((q) => q.name === newName)) {
      quickToast('Quiz name must be unique');
      return;
    }
    runInAction(() => {
      const quiz = this.quizzes.find((q) => q.name === oldName);
      if (quiz) {
        quiz.name = newName;
        // update all questions with the new quiz name
        this.questions.forEach((q) => {
          if (q.quizName === oldName) {
            q.quizName = newName;
          }
        });
        if (this.currentQuiz === oldName) {
          this.currentQuiz = newName;
        }
        this.saveState();
      }
    });
  }

  reorderQuiz(quizName: string, newPosition: number) {
    runInAction(() => {
      const quiz = this.quizzes.find((q) => q.name === quizName);
      if (quiz) {
        this.quizzes = this.quizzes.filter((q) => q.name !== quizName);
        this.quizzes.splice(newPosition, 0, quiz);
        this.saveState();
      }
    });
  }

  updateQuizDescription(quizName: string, description: string) {
    runInAction(() => {
      const quiz = this.quizzes.find((q) => q.name === quizName);
      if (quiz) {
        quiz.description = description;
        this.saveState();
      }
    });
  }

  setCurrentQuiz(quizName: string) {
    let quiz = this.quizzes.find((q) => q.name === quizName);
    if (quiz) {
      this.currentQuiz = quiz.name;
      this.saveState();
      return;
    }
    this.createNewQuiz(quizName);
    this.saveState();
    return;
  }

  deleteQuiz(quizName: string) {
    this.quizzes = this.quizzes.filter((q) => q.name !== quizName);
    // keep questions but remove quizName
    this.questions.forEach((q) => {
      if (q.quizName === quizName) {
        q.quizName = undefined;
      }
    });
    if (this.quizzes.length === 0) {
      const newQuiz = {
        name: 'Current',
        description: '',
        favourite: false,
        lastUpdated: new Date(),
      };
      this.currentQuiz = newQuiz.name;
      this.quizzes.push(newQuiz);
    } else {
      this.currentQuiz = this.quizzes[0].name;
    }
    this.saveState();
  }

  toggleFavourite(quizName: string) {
    let isFavourite = false;
    runInAction(() => {
      const MAX_FAVOURITE_COUNT = 3;
      let quiz = this.quizzes.find((q) => q.name === quizName);
      console.log('toggleFavourite start', { fav: quiz?.favourite, quizName });
      if (!quiz) {
        console.error('Quiz not found');
        return;
      }
      if (!quiz.favourite) {
        if (
          this.quizzes.filter((q) => q.favourite).length < MAX_FAVOURITE_COUNT
        ) {
          quiz.favourite = true;
        } else {
          quickToast(`You can only favourite ${MAX_FAVOURITE_COUNT} quizzes`);
        }
      } else {
        quiz.favourite = false;
      }
      console.log('toggleFavourite end', {
        fav: quiz?.favourite,
        quizName,
      });
      isFavourite = quiz.favourite;
      this.saveState();
    });
    return isFavourite;
  }

  async generateFromPrompt(prompt: String) {
    const messages: Message[] = [
      {
        role: 'system',
        content: generateFromPastQuizzesPrompt(
          this.favouriteQuizzes,
          this.questions,
        ),
      },
      { role: 'user', content: prompt.toString() },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
    });
  }

  async generateQuestions() {
    const messages: Message[] = [
      {
        role: 'system',
        content: generateFromPastQuizzesPrompt(
          this.favouriteQuizzes,
          this.questions,
        ),
      },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
    });
  }

  async generateFromPastQuizzes(quizzes: string[]) {
    // Print all quizzes sequentially with each quiz including all questions in numbered order
    let allQuestionsString = '';
    quizzes.forEach((quizName) => {
      const quizQuestions = this.questions.filter(
        (q) => q.quizName === quizName,
      );
      // sort in question order
      quizQuestions.sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
      allQuestionsString += `## ${quizName}\n`;
      quizQuestions.forEach((q) => {
        allQuestionsString += `${q.number}. ${q.question} (answer: ${q.answer})\n`;
      });
    });
    const messages: Message[] = [
      {
        role: 'system',
        content: GENERATE_QUESTIONS_FROM_PAST_QUIZZES + allQuestionsString,
      },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
    });
  }

  addCategory(category: string) {
    if (this.categories.includes(category)) {
      alert('Category already exists');
      return;
    }
    this.categories.push(category);
    this.saveState();
  }

  removeCategory(category: string) {
    this.categories = this.categories.filter((c) => c !== category);
    this.saveState();
  }

  async categorizeQuestions() {
    if (this.categories.length === 0) {
      alert('No categories found. Please add categories first.');
      return;
    }
    const messages: Message[] = [
      { role: 'system', content: CATEGORIZE_QUESTIONS },
      { role: 'user', content: '## Categories\n' + this.categories.join('\n') },
      {
        role: 'user',
        content: JSON.stringify(
          this.currentQuizQuestions.map((q) => {
            return { question: q.question, answer: q.answer };
          }),
        ),
      },
    ];
    console.log({ messages });
    const questions = await generateQuestionsHelper(messages);
    const currentQuizQuestions = this.currentQuizQuestions;
    console.log({
      questions: questions.map((q: QuestionObj) => JSON.stringify(q)),
      currentQuizQuestions,
    });
    runInAction(() => {
      questions.forEach((q: QuestionObj, i: number) => {
        currentQuizQuestions[i].category = q.category;
      });
    });
  }

  getMissingCategoriesInCurrentQuiz() {
    // if there are less than 2 question in the category, add it to the list
    const categories = this.currentQuizQuestions.reduce(
      (acc: Record<string, number>, q) => {
        if (q.category) {
          acc[q.category] = acc[q.category] ? acc[q.category] + 1 : 1;
        }
        return acc;
      },
      {},
    );
    return this.categories.filter((c) => categories[c] ?? 0 < 2);
  }

  async generateQuestionsForCategory(category: string) {
    const messages: Message[] = [
      {
        role: 'system',
        content: generateFromPastQuizzesPrompt(
          this.favouriteQuizzes,
          this.questions,
        ),
      },
      { role: 'user', content: `## Category\n${category}` },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
      this.saveState();
    });
  }

  async generateFromFilteredQuestions(inputQuestions: QuestionObj[]) {
    const randomOrder = inputQuestions.sort(() => Math.random() - 0.5);
    const questionsString = randomOrder
      .slice(-8)
      .map((q) => q.question)
      .join('\n');
    const messages: Message[] = [
      { role: 'system', content: EXPLAIN_FORMAT },
      {
        role: 'user',
        content:
          'Generate 10 new trivia questions similar to the following:\n' +
          questionsString,
      },
    ];
    const questions = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q: QuestionObj) =>
        this.addQuestion(q.question, q.answer),
      );
      this.saveState();
    });
  }

  async generateSimilarQuestions(question: string) {
    const messages: Message[] = [
      { role: 'system', content: GENERATE_SIMILAR_QUESTIONS },
      {
        role: 'user',
        content: question,
      },
    ];
    const questions: QuestionObj[] = await generateQuestionsHelper(messages);
    runInAction(() => {
      questions.forEach((q) => this.addQuestion(q.question, q.answer));
    });
  }

  async factCheck(question: QuestionObj) {
    const messages: Message[] = [
      {
        role: 'system',
        content: FACT_CHECK,
      },
      {
        role: 'user',
        content: `Q) ${question.question}\nA) ${question.answer}`,
      },
    ];
    const response = await callAi(messages);
    return response;
  }

  async askQuestion(question: QuestionObj, chatAreaQuestion: string) {
    const messages: Message[] = [
      {
        role: 'system',
        content: chatAreaQuestion,
      },
      {
        role: 'user',
        content: `Q) ${question.question}\nA) ${question.answer}`,
      },
    ];
    const response = await callAi(messages);
    return response;
  }

  updateQuestion(id: number, updatedQuestion: string, updatedAnswer: string) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.question = updatedQuestion;
        question.answer = updatedAnswer;
        this.saveState();
      });
    }
  }

  addQuestion(question: string, answer: string): QuestionObj {
    const questionObj = new QuestionObj({
      id: this.nextId++,
      question,
      answer,
      approved: false,
    });
    runInAction(() => {
      this.questions.push(questionObj);
      this.saveState();
      // if over 1000 questions unapproved or not in a quiz, bring it down to 800
      const scratchpadQuestions = this.questions.filter(
        (q) => !q.approved && !q.quizName,
      );
      if (scratchpadQuestions.length > 1000) {
        const toRemove = scratchpadQuestions.slice(800);
        this.removeQuestions(new Set(toRemove.map((q) => q.id)));
      }
    });
    return questionObj;
  }

  removeQuestion(id: number) {
    runInAction(() => {
      this.questions = this.questions.filter((q) => q.id !== id);
      this.saveState();
    });
  }

  removeQuestions(ids: Set<number>) {
    runInAction(() => {
      this.questions = this.questions.filter((q) => !ids.has(q.id));
      this.saveState();
    });
  }

  approveQuestion(id: number) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.approved = true;
        this.saveState();
      });
    }
  }

  unapproveQuestion(id: number) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.approved = false;
        this.saveState();
      });
    }
  }

  changeNumber(id: number, newNumber: number) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.number = newNumber;
        this.saveState();
      });
    }
  }

  addQuestionToQuiz(id: number) {
    const currentQuizQuestions = this.currentQuizQuestions;
    if (currentQuizQuestions.some((q) => q.id === id)) {
      quickToast('Question already in quiz');
      return;
    }
    const questionIndex = this.questions.findIndex((q) => q.id === id);
    if (questionIndex !== -1) {
      runInAction(() => {
        const updatedQuestion = {
          ...this.questions[questionIndex],
          quizName: this.currentQuiz,
          approved: true,
          number:
            Math.max(...currentQuizQuestions.map((q) => q.number ?? 0), 0) + 1,
        };
        this.questions[questionIndex] = updatedQuestion;

        const currentQuizIndex = this.quizzes.findIndex(
          (q) => q.name === this.currentQuiz,
        );
        if (currentQuizIndex === -1) {
          throw new Error('Current quiz not found');
        }
        this.quizzes[currentQuizIndex] = {
          ...this.quizzes[currentQuizIndex],
          lastUpdated: new Date(),
        };
        this.saveState();
      });
    } else {
      console.error('Question not found', id);
    }
  }

  removeQuestionFromQuiz(id: number) {
    const question = this.questions.find((q) => q.id === id);
    if (question) {
      runInAction(() => {
        question.quizName = undefined;
        // update all numbers higher than this to be 1 number lower
        const currentQuizQuestions = this.currentQuizQuestions;
        if (question.number) {
          currentQuizQuestions.forEach((q) => {
            if (q.number && q.number > question.number!) {
              q.number -= 1;
            }
          });
        }
        question.number = undefined;
        this.saveState();
      });
    }
  }

  saveState() {
    localStorage.setItem('questions', JSON.stringify(this.questions));
    localStorage.setItem('quizzes', JSON.stringify(this.quizzes));
    localStorage.setItem('currentQuiz', JSON.stringify(this.currentQuiz));
    localStorage.setItem('categories', JSON.stringify(this.categories));
    localStorage.setItem('nextId', JSON.stringify(this.nextId));
  }

  loadState() {
    // TODO: add a function to check if the state is valid
    /*
    - If questions mention a quiz make sure that quiz exists
    - Check questions only reference saved categories
    - Make sure nextId is greater than all question ids
    */
    const savedQuestions = localStorage.getItem('questions');
    const savedQuizzes = localStorage.getItem('quizzes');
    const savedCurrentQuiz = localStorage.getItem('currentQuiz');
    const savedCategories = localStorage.getItem('categories');
    const savedNextId = localStorage.getItem('nextId');

    if (savedQuestions) {
      this.questions = JSON.parse(savedQuestions);
    } else {
      this.questions = [
        {
          id: 1,
          question: 'Lisbon, Eureka, and Meyer are all types of which fruit?',
          answer: 'Lemon\r',
          approved: true,
          number: 1,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 2,
          question:
            "In the TV show 'Friends', what is the name of Ross Geller's second wife?",
          answer: 'Emily\r',
          approved: true,
          number: 2,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 3,
          question: 'Who was the first president of the United States?',
          answer: 'George Washington\r',
          approved: true,
          number: 3,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 4,
          question: 'In which country is the oldest pub in the world?',
          answer: "Ireland (Sean's Bar in Athlone)\r",
          approved: true,
          number: 4,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 5,
          question:
            "Who is the author of the 1865 novel 'Alice's Adventures in Wonderland'?",
          answer: 'Lewis Carroll\r',
          approved: true,
          number: 5,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 6,
          question: 'Which country is the biggest exporter of palm oil?',
          answer: 'Indonesia\r',
          approved: true,
          number: 6,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 7,
          question:
            'What is the traditional, colourful South Korean attire called?',
          answer: 'Hanbok\r',
          approved: true,
          number: 7,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 8,
          question: 'What is the capital of Kenya?',
          answer: 'Nairobi\r',
          approved: true,
          number: 8,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 9,
          question:
            "In what year did John F. Kennedy get assassinated, Martin Luther King Jr. deliver his 'I Have a Dream' speech, and the Beatles release their debut album 'Please Please Me'?",
          answer: '1963\r',
          approved: true,
          number: 9,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 10,
          question: "What soft drink was originally named 'Brad's Drink'?",
          answer: 'Pepsi\r',
          approved: true,
          number: 10,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 11,
          question: 'Which country is the most densely populated in the world?',
          answer: 'Monaco\r',
          approved: true,
          number: 11,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 12,
          question: 'In which sport is the Stanley Cup awarded?',
          answer: 'Ice Hockey\r',
          approved: true,
          number: 12,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 13,
          question: "Who said, 'To infinity and beyond'?",
          answer: 'Buzz Lightyear (in Toy Story)\r',
          approved: true,
          number: 13,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 14,
          question: 'In which city was Archduke Franz Ferdinand assassinated?',
          answer: 'Sarajevo\r',
          approved: true,
          number: 14,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 15,
          question: 'What 5 cards make up the worst possible poker hand?',
          answer: '2, 3, 4, 5, 7 (with at least one off suit)\r',
          approved: true,
          number: 15,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 16,
          question: "In internet slang, what does 'TL;DR' stand for?",
          answer: "Too Long; Didn't Read\r",
          approved: true,
          number: 16,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 17,
          question: 'Who is the most decorated Olympian of all time?',
          answer: 'Michael Phelps\r',
          approved: true,
          number: 17,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 18,
          question: "Which country's flag has more than 4 sides?",
          answer: 'Nepal\r',
          approved: true,
          number: 18,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 19,
          question:
            "Which song contains the lyrics 'Cause I got that sunshine in my pocket, got that good soul in my feet'?",
          answer: "Can't Stop the Feeling!' by Justin Timberlake\r",
          approved: true,
          number: 19,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 20,
          question: 'In which US state is Mount Rushmore located?',
          answer: 'South Dakota\r',
          approved: true,
          number: 20,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 21,
          question: 'What is the real name of the rapper known as Jay-Z?',
          answer: 'Shawn Carter\r',
          approved: true,
          number: 21,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 22,
          question:
            'Which movie studio’s logo features an image of a boy fishing on the moon?',
          answer: 'DreamWorks\r',
          approved: true,
          number: 22,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 23,
          question: 'Which conglomerate owns Cadbury, Oreo, and belVita?',
          answer: 'Mondelez International\r',
          approved: true,
          number: 23,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 24,
          question:
            "In the Monty Hall problem, what is the optimal strategy? Keep your original choice, switch doors, or it doesn't matter.",
          answer:
            'Switch doors, because it increases your probability of winning from 1/3 to 2/3.\r',
          approved: true,
          number: 24,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 25,
          question:
            "In 'Take Me Out to the Ballgame,' what two snacks are mentioned?",
          answer: 'Peanuts and Cracker Jack\r',
          approved: true,
          number: 25,
          quizName: 'Gusto Quiz (July 26 2024)',
        },
        {
          id: 26,
          question:
            "In what year did the 'Bay of Pigs Invasion' take place, Yuri Gagarin became the first human to journey into outer space, and Barbie's boyfriend 'Ken' was first introduced?",
          answer: '1961\r',
          approved: true,
          number: 1,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 27,
          question: 'From which country did Gouda cheese originate?',
          answer: 'The Netherlands\r',
          approved: true,
          number: 2,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 28,
          question: 'Who is the founder of Virgin Group?',
          answer: 'Richard Branson\r',
          approved: true,
          number: 3,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 29,
          question:
            'In swimming, how many individual strokes are used in an Individual Medley (IM) race?',
          answer: 'Four (Butterfly, Backstroke, Breaststroke, Freestyle)\r',
          approved: true,
          number: 4,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 30,
          question: "Whose slogan is 'Don’t Leave Home Without It'?",
          answer: 'American Express\r',
          approved: true,
          number: 5,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 31,
          question: 'What device is used to measure atmospheric pressure?',
          answer: 'Barometer\r',
          approved: true,
          number: 6,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 32,
          question: 'What is the capital of Argentina?',
          answer: 'Buenos Aires\r',
          approved: true,
          number: 7,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 33,
          question:
            'Which actor plays the role of Mr. Bean in the movies and TV series?',
          answer: 'Rowan Atkinson\r',
          approved: true,
          number: 8,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 34,
          question: 'Which conglomerate owns Maybelline, Garnier and CeraVe?',
          answer: "L'Oréal\r",
          approved: true,
          number: 9,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 35,
          question: 'Which country is the top producer of wool?',
          answer: 'Australia\r',
          approved: true,
          number: 10,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 36,
          question:
            'Which novel and later film, revolves around the love life of Allie Hamilton and Noah Calhoun?',
          answer: 'The Notebook\r',
          approved: true,
          number: 11,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 37,
          question: 'Who was the lead scientist of the Manhattan Project?',
          answer: 'Robert Oppenheimer\r',
          approved: true,
          number: 12,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 38,
          question:
            'Which song includes the lyrics “I wanna stand with you on a mountain, I wanna bathe with you in the sea”?',
          answer: "Truly Madly Deeply' by Savage Garden\r",
          approved: true,
          number: 13,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 39,
          question: 'In technology, what does VPN stand for?',
          answer: 'Virtual private network\r',
          approved: true,
          number: 14,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 40,
          question: 'How many chambers are there in the human heart?',
          answer: 'Four\r',
          approved: true,
          number: 15,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 41,
          question:
            "Coldplay collaborated with which major female artist on the song 'Princess of China'?",
          answer: 'Rihanna\r',
          approved: true,
          number: 16,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 42,
          question:
            'In Greek mythology, who was the king who turned all he touched into gold?',
          answer: 'King Midas\r',
          approved: true,
          number: 17,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 43,
          question:
            "What film portrays a young girl's adventure in a hidden world with strange creatures after her parents are turned into pigs?",
          answer: 'Spirited Away\r',
          approved: true,
          number: 18,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 44,
          question:
            "Which Korean automobile company is responsible for the 'Elantra' and 'Santa Fe'?",
          answer: 'Hyundai\r',
          approved: true,
          number: 19,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 45,
          question: 'Where in the body would you find the latissimus dorsi?',
          answer: 'Back\r',
          approved: true,
          number: 20,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 46,
          question: "What is a 'Full House' in poker?",
          answer: "It's a hand that contains three of a kind and a pair\r",
          approved: true,
          number: 21,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 47,
          question:
            'What type of cocktail typically includes vodka, tomato juice, and various spices?',
          answer: 'Bloody Mary\r',
          approved: true,
          number: 22,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 48,
          question: 'Which astrological star signs fall in the month of July?',
          answer: 'Cancer and Leo\r',
          approved: true,
          number: 23,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 49,
          question:
            'Which state in the USA shares the longest border with Mexico?',
          answer: 'Texas\r',
          approved: true,
          number: 24,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 50,
          question:
            'How many tiles are you allowed to have on your rack in the standard version of Scrabble?',
          answer: '7',
          approved: true,
          number: 25,
          quizName: 'Gusto Quiz (July 19 2024)',
        },
        {
          id: 51,
          question:
            'What is the total number of double-letter score tiles on a standard Scrabble board?',
          answer: '12',
          approved: false,
        },
        {
          id: 52,
          question: "In Scrabble, how many points is the letter 'Q' worth?",
          answer: '10 points',
          approved: false,
        },
        {
          id: 53,
          question:
            'Which two letters have the highest point value in Scrabble?',
          answer: 'Q and Z',
          approved: false,
        },
        {
          id: 54,
          question:
            'How many triple-word score tiles are there on a standard Scrabble board?',
          answer: '8',
          approved: false,
        },
        {
          id: 55,
          question:
            'What is the maximum number of tiles a player can exchange on their turn in Scrabble?',
          answer: '7',
          approved: false,
        },
        {
          id: 56,
          question:
            'How many blank tiles are there in a standard Scrabble set?',
          answer: '2',
          approved: false,
        },
        {
          id: 57,
          question: "How many points is the letter 'E' worth in Scrabble?",
          answer: '1 point',
          approved: false,
        },
        {
          id: 58,
          question:
            'What is the highest possible score for a single word in Scrabble?',
          answer: 'Oxyphenbutazone, up to 1,782 points',
          approved: false,
        },
        {
          id: 59,
          question: 'In Scrabble, how many tiles are in total in the game?',
          answer: '100',
          approved: false,
        },
        {
          id: 60,
          question:
            'What is the minimum number of players required to play a game of Scrabble?',
          answer: '2',
          approved: false,
        },
        {
          id: 61,
          question:
            "Which German automobile company produces the 'A4' and 'Q7' models?",
          answer: 'Audi',
          approved: false,
        },
        {
          id: 62,
          question:
            "Which Japanese automobile company is known for the 'Corolla' and 'Camry'?",
          answer: 'Toyota',
          approved: false,
        },
        {
          id: 63,
          question:
            "Which South Korean company manufactures the 'Kona' and 'Sorento'?",
          answer: 'Kia',
          approved: false,
        },
        {
          id: 64,
          question:
            "Which American automobile company produces the 'Mustang' and 'F-150'?",
          answer: 'Ford',
          approved: false,
        },
        {
          id: 65,
          question:
            "Which Italian luxury sports car brand is known for models like the '488' and 'Portofino'?",
          answer: 'Ferrari',
          approved: false,
        },
        {
          id: 66,
          question:
            "Which French automobile company is responsible for the 'Clio' and 'Megane'?",
          answer: 'Renault',
          approved: false,
        },
        {
          id: 67,
          question:
            "Which Swedish company is known for producing the 'XC90' and 'S60' models?",
          answer: 'Volvo',
          approved: false,
        },
        {
          id: 68,
          question:
            "Which Korean automobile company produces the 'Optima' and 'Sportage'?",
          answer: 'Kia',
          approved: false,
        },
        {
          id: 69,
          question:
            "Which British luxury automobile company manufactures the 'Phantom' and 'Ghost'?",
          answer: 'Rolls-Royce',
          approved: false,
        },
        {
          id: 70,
          question:
            "Which American electric vehicle company is known for the 'Model S' and 'Model X'?",
          answer: 'Tesla',
          approved: false,
        },
        {
          id: 71,
          question: 'What is the largest desert in Africa?',
          answer: 'The Sahara Desert',
          approved: false,
        },
        {
          id: 72,
          question: 'Which African country has the highest population?',
          answer: 'Nigeria',
          approved: false,
        },
        {
          id: 73,
          question: 'What is the capital of Egypt?',
          answer: 'Cairo',
          approved: false,
        },
        {
          id: 74,
          question:
            'What river runs through six countries in northeastern Africa, including Uganda and Sudan?',
          answer: 'The Nile River',
          approved: false,
        },
        {
          id: 75,
          question:
            'Which African country is known as the Land of a Thousand Hills?',
          answer: 'Rwanda',
          approved: false,
        },
        {
          id: 76,
          question: 'What is the capital of South Africa?',
          answer:
            'Pretoria (administrative), Bloemfontein (judicial), and Cape Town (legislative)',
          approved: false,
        },
        {
          id: 77,
          question: 'What is the capital of Nigeria?',
          answer: 'Abuja',
          approved: false,
        },
        {
          id: 78,
          question: 'Which country is Mount Kilimanjaro located in?',
          answer: 'Tanzania',
          approved: false,
        },
        {
          id: 79,
          question: 'What is the capital of Ethiopia?',
          answer: 'Addis Ababa',
          approved: false,
        },
        {
          id: 80,
          question: 'What is the smallest country in Africa by land area?',
          answer: 'Seychelles',
          approved: false,
        },
        {
          id: 81,
          question: 'In which city was John F. Kennedy assassinated?',
          answer: 'Dallas',
          approved: false,
        },
        {
          id: 82,
          question:
            "What was the title of Martin Luther King Jr.'s famous speech delivered during the March on Washington?",
          answer: "'I Have a Dream'",
          approved: false,
        },
        {
          id: 83,
          question: "What was the name of the Beatles' debut studio album?",
          answer: "'Please Please Me'",
          approved: false,
        },
        {
          id: 84,
          question: 'Who assassinated John F. Kennedy?',
          answer: 'Lee Harvey Oswald',
          approved: false,
        },
        {
          id: 85,
          question:
            'What year did Martin Luther King Jr. receive the Nobel Peace Prize?',
          answer: '1964',
          approved: false,
        },
        {
          id: 86,
          question: "Which Beatle was known as the 'quiet Beatle'?",
          answer: 'George Harrison',
          approved: false,
        },
        {
          id: 87,
          question: 'In what year was Martin Luther King Jr. assassinated?',
          answer: '1968',
          approved: false,
        },
        {
          id: 88,
          question:
            "Which U.S. President was in office when Martin Luther King Jr. delivered his 'I Have a Dream' speech?",
          answer: 'John F. Kennedy',
          approved: false,
        },
        {
          id: 89,
          question:
            "What was the release date of The Beatles' album 'Please Please Me'?",
          answer: 'March 22, 1963',
          approved: false,
        },
        {
          id: 90,
          question:
            'What did John F. Kennedy famously say in his inaugural address?',
          answer:
            "'Ask not what your country can do for you – ask what you can do for your country.'",
          approved: false,
        },
      ];
    }
    if (savedQuizzes) {
      this.quizzes = JSON.parse(savedQuizzes);
    } else {
      this.quizzes = [
        {
          name: 'Gusto Quiz (July 26 2024)',
          description: '',
          favourite: true,
          lastUpdated: new Date(),
        },
        {
          name: 'Gusto Quiz (July 19 2024)',
          description: '',
          favourite: true,
          lastUpdated: new Date(),
        },
      ];
    }
    if (savedCurrentQuiz) {
      this.currentQuiz = JSON.parse(savedCurrentQuiz);
    } else {
      this.currentQuiz = this.quizzes[0].name;
    }
    if (savedCategories) {
      this.categories = JSON.parse(savedCategories);
    } else {
      this.categories = [
        'Food and Beverages',
        'Music and Songs',
        'Literature and Authors',
        'Movies and Directors',
        'Companies and Brands',
        'Sports',
        'Science and Technology',
        'Geography and Places',
        'History and Events',
      ];
    }
    if (savedNextId) {
      this.nextId = JSON.parse(savedNextId);
    } else {
      this.questions.forEach((q) => {
        this.nextId = Math.max(this.nextId, q.id + 1);
      });
    }
  }

  exportToCSV(quizName: string) {
    const header = 'question number\tquestion\tanswer\n';
    const questions = this.questions
      .filter((q) => q.quizName === quizName)
      .sort((a, b) => (a.number ?? 0) - (b.number ?? 0));
    const rows = questions
      .map((q, index) => `${index + 1}\t${q.question}\t${q.answer}`)
      .join('\n');
    const csvContent = header + rows;
    const blob = new Blob([csvContent], { type: 'text/tsv;charset=utf-8;' });
    saveAs(blob, 'quiz.tsv');
  }

  removeDuplicateQuestions() {
    const questionSet = new Set<string>();
    const newQuestions = this.questions.filter((q) => {
      if (questionSet.has(q.quizName + q.question + q.answer)) {
        return false;
      }
      questionSet.add(q.quizName + q.question + q.answer);
      return true;
    });
    this.questions = newQuestions;
    this.saveState();
  }

  async importFromTSV(file: File | undefined) {
    if (file) {
      const text = await file.text();
      const lines = text.split('\n');
      const questions: QuestionObj[] = [];
      let newQuizzes = new Set<string>();
      for (let i = 0; i < lines.length; i++) {
        const [quizName, number, question, answer] = lines[i].split('\t');
        if (this.quizzes.every((q) => q.name !== quizName)) {
          newQuizzes.add(quizName);
        }
        // if question already exists, and is in the same quiz, skip
        if (
          this.questions.some(
            (q) =>
              q.quizName === quizName &&
              q.question === question &&
              q.answer === answer,
          )
        ) {
          continue;
        }
        questions.push(
          new QuestionObj({
            number: parseInt(number),
            question,
            answer,
            id: this.nextId++,
            quizName: quizName,
            approved: true,
          }),
        );
      }
      runInAction(() => {
        this.questions = this.questions.concat(questions);
        this.quizzes = this.quizzes.concat(
          Array.from(newQuizzes).map((quiz) => ({
            name: quiz,
            description: '',
            favourite: false,
            lastUpdated: new Date(),
          })),
        );
        this.saveState();
      });
    }
  }
}

const quizStore = new QuizStore();
export default quizStore;
